.damac
   padding-bottom: 150px
   &__logo
      max-width: 900px
      padding: 10% 20px 0
      margin: 0 auto 

      img
         width: 345px
         @media (max-width: 1330px)
            width: calc(170px + (345 - 170) * ((100vw - 400px) / (1330 - 400)))




.damac__title
   margin: 120px 0 48px


.damac-pages
   display: grid
   grid-template-columns: repeat(3, 1fr)
   column-gap: 24px
   row-gap: 32px


@media(max-width: 768px)
   .damac-pages
      display: grid
      grid-template-columns: repeat(2, 1fr)
      column-gap: 24px
      row-gap: 32px

   .damac__title
      margin: 70px 0 32px

   .damac
      padding-bottom: 120px


@media(max-width: 400px)
   .damac-pages
      display: grid
      grid-template-columns: repeat(1, 1fr)
      row-gap: 32px

   .damac__title
      margin: 48px 0 24px

   .damac
      padding-bottom: 100px

.damac-page 
   &__image
      height: 280px
   img
      object-fit: cover
      height: 100%
      border-radius: 15px
      width: 100%
      transition: .3s
      cursor: pointer

.damac-page img:hover
   box-shadow: 0 0 10px #b8b7b5
   width: 102%
   margin: -2% 0 0 -1%

.damac-page__OAE
   font-size: 16px
   display: inline-block
   color: #CFAC6A
   padding: 5px 12px
   margin: 5px 0
   border-radius: 20px
   background-color: #b1b0ae14

.damac-page__price
   display: block
   color: #CFAC6A

.damac-page__discription
   margin-top: 12px
   color: #7B8E97
   line-height: 150%
