.menu__mobile
    position: fixed
    z-index: 2
    width: 100%
    height: 100vh
    transition: .5s
    background-color: #fafafa
    padding: 30px 0 70px
    transform: translateX(-100vw)
    left: 0
    top: 92px
    font-size: 1.5rem
    color: #1f2e36
.menu
    flex-direction: column
    &__item
        display: flex
        align-items: center
        justify-content: space-between
        color: var(--materasso)
        padding: 16px 0
    &__title
        cursor: pointer
        display: flex
        justify-content: space-between
        align-items: center
        padding: 16px 32px
        text-transform: uppercase
        font-size: 1.25rem
        line-height: 2rem
        text-align: center
        transition: .3s
        border-bottom: 1px solid #CED3D680

.open
    .menu
        display: flex
    .menu__mobile   
        transform: translateX(0vw)
        // & + .nav__wrap .hamburger
        //     transform: scale(1.1)
        //     @media (max-width: 560px)
        //         transform: scale(0.9)
        //     .line__middle
        //         opacity: 1 
        //         display: none   
        //     .line__one
        //         transform: rotate(42deg) translate(8px, 7px) 
        //     .line__three
        //         transform: rotate(-42deg) translate(8px, -7px)