nav
    position: relative
    z-index: 3
    background-color: #1F2E36
    color: #fff
    @media ( max-width: 768px )
        position: fixed
        top: 0
        width: 100%
        
.nav
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    &-size
        height: 113px
        
    &__left
        display: flex
    &__right
        display: flex
        align-items: center 
.header__wrap + .nav-size
    @media ( max-width: 768px )
        display: none
.phone
    margin-top: -22px
    margin-right: 16px
.menu
    display: flex
    align-items: center
    margin-left: 70px
    &__item
        font-size: 20px
        margin-right: 32px
        transition: .2s linear
        @media (min-width: 768px)
            @media (max-width: 1330px)
                font-size: calc(10px + (20 - 10) * ((100vw - 768px) / (1330 - 768)))
        &:hover
            color: #7c8b93
.logo
    width: 125px
    img
        width: 100% 
        display: block
.lang
    display: flex
    text-transform: capitalize
    &__arrow
        margin-left: 11px
        cursor: pointer
        img
            width: 10px
    &__wrap
        position: relative
        &:hover .lang-menu
            display: block
    &-menu
        &__wrap
            position: absolute
            z-index: 4
            top: 15px
            width: 55px
            height: 88px
            font-size: .9rem
            right: -12px
        display: none
        background: #1F2E36 
        box-sizing: content-box
        cursor: pointer
        padding: 10px 0
        line-height: 1.5rem
        border: 1px solid #E6EDF0
        opacity: 0
        width: 55px
        text-align: center
        transition: .3s linear
        transform: translateY(-10px)
        animation: lang-show .3s linear 1 .3s forwards
        @keyframes lang-show
            100%
                opacity: 1
                transform: translateY(15px)
    &__item
        &:hover
            color: #7c8b93

@media screen and (max-width: 1100px)
    .menu
        margin-left: 40px
        &__item
            margin-right: 24px
    .logo
        width: 110px
        img
            width: 100% 

@media screen and (max-width: 768px)
    .menu
        display: none
    .nav-size
        height: 92px
@media screen and (max-width: 500px)
    .phone
        display: none

