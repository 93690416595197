
.floor-plan
   display: flex
   margin-top: 48px
   justify-content: space-between
   align-items: flex-start


.floor-name
   cursor: pointer
   display: block
   white-space: nowrap
   padding: 10px 20px 10px 10px
   margin: 0

.floor-name.active
   background-color: #fff

.floor
   img
      width: 100%



@media(max-width: 400px)
   .floor-plan
      display: block
      margin-top: 32px

   .floor-name
      cursor: pointer
      display: block
      white-space: nowrap
      padding: 10px 0
      margin: 0
      text-align: center