.cards
    background: #FFFFFF
    border-radius: 36.3213px
    margin-top: 120px
    @media (max-width: 940px)
        margin-top: 70px
    @media (max-width: 600px)
        margin-top: 48px
    &.wrap
        padding: 36px 50px
        @media (max-width: 940px)
            padding: 24px 32px
        @media (max-width: 600px)
            padding: 24px
            width: calc(100% - 48px)
    &:last-child
        margin-bottom: 120px
        @media (max-width: 600px)
            margin-bottom: 48px
        @media (max-width: 940px)
            margin-bottom: 70px
    &__wrap
        position: relative
        display: flex
        justify-content: space-between
        width: 100%
        margin: 48px 0 0px
        z-index: 0
.photo
    position: absolute
    z-index: 2
    padding: 9px 18px
    background-color: rgba(57, 72, 80, 0.5)
    border-radius: 12px
    color: #fff
    top: 36px
    left: 36px
.swiper-slide-thumb-active
    >div
        opacity: 1
.swiper
    width: 100%
    &-pagination
        display: flex
        justify-content: center
        margin-top: 30px
        &-bullet 
            display: block
            margin: 0 15px
            width: 295px
            padding-bottom: 15.3%
            border: 1px solid #68b8ff00
            opacity: 1
            border-radius: 13px
            background-repeat: no-repeat
            background-position: center -250px
            background-size: cover
            transition: .4s
            opacity: 0.4
            &:nth-child(1)
                background-image: url("../../public/img/cards/riviera1.png")
                background-position: center
                margin-left: 0
            &:nth-child(2)
                background-image: url("../../public/img/cards/riviera2.png")
                background-position: center
            &:nth-child(3)
                background-image: url("../../public/img/cards/riviera3.png")
                background-position: center
            &:nth-child(4)
                background-image: url("../../public/img/cards/riviera4.png")
                background-position: center
            &:nth-child(5)
                background-image: url("../../public/img/cards/riviera5.png")
                background-position: center
                margin-right: 0
            &-active 
                opacity: 1
            @media ( max-width: 600px ) 
                &:nth-child(1)
                    background: none 
                    background-color: #fff 
                &:nth-child(2)
                    background: none 
                    background-color: #fff 
                &:nth-child(3)
                    background: none 
                    background-color: #fff 
                &:nth-child(4)
                    background: none 
                    background-color: #fff 
                &:nth-child(5)
                    background: none  
                    background-color: #fff
                
                    
    &-wrapper
        height: auto
.card
    width: 100%
    text-align: center
    margin: 0 auto
    &__link
        color: #CFAC6A
        display: flex
        align-items: center
        img
            margin-right: 15px
    &__image
        border-radius: 13px
        img
            width: 100%
            border-radius: 13px
.arrows
    position: absolute
    display: flex
    pointer-events: none
    justify-content: space-between
    z-index: 2
    left: 0
    right: 0
    top: 34.5%
    margin: 0 auto
    width: 100%
    padding: 0 36px
    > div
        pointer-events: all
    @media (max-width: 940px)
        padding: 0 24px
    svg
        width: 54px
        @media (max-width: 940px)
            width: 36px
.investment
    .swiper-pagination
        &-bullet
            margin: 0 8px
            &:nth-child(1)
                background-image: url("../../public/img/cards/reve1.png")
                background-position: center top
                margin: 0
            &:nth-child(2)
                background-image: url("../../public/img/cards/reve2.png")
                background-position: center top
            &:nth-child(3)
                background-image: url("../../public/img/cards/reve3.png")
                background-position: center top
            &:nth-child(4)
                background-image: url("../../public/img/cards/reve4.png")
                background-position: center top
            &:nth-child(5)
                background-image: url("../../public/img/cards/reve5.png")
                background-position: center top
                margin: 0
            @media ( max-width: 600px ) 
                &:nth-child(1)
                    background: none 
                    background-color: #fff 
                &:nth-child(2)
                    background: none 
                    background-color: #fff 
                &:nth-child(3)
                    background: none 
                    background-color: #fff 
                &:nth-child(4)
                    background: none 
                    background-color: #fff 
                &:nth-child(5)
                    background: none  
                    background-color: #fff
.slider
    .swiper-pagination
        margin-top: 0
        display: none
        @media (max-width: 600px)
            display: flex
        &-bullet
            margin: 0 8px
            &:nth-child(1)
                background: none 
                background-color: #fff 
            &:nth-child(2)
                background: none 
                background-color: #fff 
            &:nth-child(3)
                background: none 
                background-color: #fff 
            &:nth-child(4)
                background: none 
                background-color: #fff 
            &:nth-child(5)
                background: none  
                background-color: #fff

.sari
    .swiper-pagination
        &-bullet
            margin: 0 8px
            &:nth-child(1)
                background-image: url("../../public/img/cards/sari1.png")
                background-position: center top
            &:nth-child(2)
                background-image: url("../../public/img/cards/sari2.png")
                background-position: center top
            &:nth-child(3)
                background-image: url("../../public/img/cards/sari3.png")
                background-position: center top
            &:nth-child(4)
                background-image: url("../../public/img/cards/sari4.png")
                background-position: center top
            &:nth-child(5)
                background-image: url("../../public/img/cards/sari5.png")
                background-position: center top
            @media ( max-width: 600px ) 
                &:nth-child(1)
                    background: none 
                    background-color: #fff 
                &:nth-child(2)
                    background: none 
                    background-color: #fff 
                &:nth-child(3)
                    background: none 
                    background-color: #fff 
                &:nth-child(4)
                    background: none 
                    background-color: #fff 
                &:nth-child(5)
                    background: none  
                    background-color: #fff
.safa-one-slider
    .swiper-pagination
        &-bullet
            margin: 0 8px
            &:nth-child(1)
                background-image: url("../../public/img/safa-one/main-slide.png")
                background-position: center top
            &:nth-child(2)
                background-image: url("../../public/img/safa-one/slide1.png")
                background-position: center top
            &:nth-child(3)
                background-image: url("../../public/img/safa-one/slide2.png")
                background-position: center top
            &:nth-child(4)
                background-image: url("../../public/img/safa-one/slide3.png")
                background-position: center top
            &:nth-child(5)
                background-image: url("../../public/img/safa-one/image4.png")
                background-position: center top
            @media ( max-width: 600px ) 
                &:nth-child(1)
                    background: none 
                    background-color: #fff 
                &:nth-child(2)
                    background: none 
                    background-color: #fff 
                &:nth-child(3)
                    background: none 
                    background-color: #fff 
                &:nth-child(4)
                    background: none 
                    background-color: #fff 
                &:nth-child(5)
                    background: none  
                    background-color: #fff

.chick-tower-slider
    .swiper-pagination
        &-bullet
            margin: 0 8px
            &:nth-child(1)
                background-image: url("../../public/img/chick-tower/main-slide.png")
                background-position: center top
            &:nth-child(2)
                background-image: url("../../public/img/chick-tower/slide1.png")
                background-position: center top
            &:nth-child(3)
                background-image: url("../../public/img/chick-tower/slide2.png")
                background-position: center top
            &:nth-child(4)
                background-image: url("../../public/img/chick-tower/slide3.png")
                background-position: center top
            @media ( max-width: 600px ) 
                &:nth-child(1)
                    background: none 
                    background-color: #fff 
                &:nth-child(2)
                    background: none 
                    background-color: #fff 
                &:nth-child(3)
                    background: none 
                    background-color: #fff 
                &:nth-child(4)
                    background: none 
                    background-color: #fff 
                &:nth-child(5)
                    background: none  
                    background-color: #fff

.luma
    .swiper-pagination
        &-bullet
            margin: 0 8px
            &:nth-child(1)
                background-image: url("../../public/img/luma/luma1.png")
                background-position: center top
            &:nth-child(2)
                background-image: url("../../public/img/luma/luma2.png")
                background-position: center top
            &:nth-child(3)
                background-image: url("../../public/img/luma/luma3.png")
                background-position: center top
            &:nth-child(4)
                background-image: url("../../public/img/luma/luma4.png")
                background-position: center top
            &:nth-child(5)
                background-image: url("../../public/img/luma/luma5.png")
                background-position: center top
            @media ( max-width: 600px ) 
                &:nth-child(1)
                    background: none 
                    background-color: #fff 
                &:nth-child(2)
                    background: none 
                    background-color: #fff 
                &:nth-child(3)
                    background: none 
                    background-color: #fff 
                &:nth-child(4)
                    background: none 
                    background-color: #fff 
                &:nth-child(5)
                    background: none  
                    background-color: #fff

.adhara
    .swiper-pagination
        &-bullet
            margin: 0 8px
            &:nth-child(1)
                background-image: url("../../public/img/cards/adhara1.jpg")
                background-position: center top
            &:nth-child(2)
                background-image: url("../../public/img/cards/adhara2.jpg")
                background-position: center top
            &:nth-child(3)
                background-image: url("../../public/img/cards/adhara3.jpg")
                background-position: center top
            &:nth-child(4)
                background-image: url("../../public/img/cards/adhara4.jpg")
                background-position: center top
            &:nth-child(5)
                background-image: url("../../public/img/cards/adhara5.jpg")
                background-position: center top
            @media ( max-width: 600px ) 
                &:nth-child(1)
                    background: none 
                    background-color: #fff 
                &:nth-child(2)
                    background: none 
                    background-color: #fff 
                &:nth-child(3)
                    background: none 
                    background-color: #fff 
                &:nth-child(4)
                    background: none 
                    background-color: #fff 
                &:nth-child(5)
                    background: none  
                    background-color: #fff

.habtoor
    .swiper-pagination
        &-bullet
            margin: 0 8px
            &:nth-child(1)
                background-image: url("../../public/img/cards/habtoor1.jpg")
                background-position: center top
            &:nth-child(2)
                background-image: url("../../public/img/cards/habtoor2.jpg")
                background-position: center top
            &:nth-child(3)
                background-image: url("../../public/img/cards/habtoor3.jpg")
                background-position: center top
            &:nth-child(4)
                background-image: url("../../public/img/cards/habtoor4.jpg")
                background-position: center top
            &:nth-child(5)
                background-image: url("../../public/img/cards/habtoor5.jpg")
                background-position: center top
            @media ( max-width: 600px ) 
                &:nth-child(1)
                    background: none 
                    background-color: #fff 
                &:nth-child(2)
                    background: none 
                    background-color: #fff 
                &:nth-child(3)
                    background: none 
                    background-color: #fff 
                &:nth-child(4)
                    background: none 
                    background-color: #fff 
                &:nth-child(5)
                    background: none  
                    background-color: #fff


.card__image 
    height: 700px
    @media(max-width: 1300px)
        height:  calc(210px + (700 - 210) * ((100vw - 390px) / (1300 - 390)))
    img 
        width: 100%
        height: 100%
        margin: 0
        
.chick-tower-slider
    .photo
        margin-top: 20px



.swiper-button-disabled
    opacity: 0.3
    transition: .3
@media screen and (max-width: 940px)     
    .swiper-pagination-bullet
        margin: 0 8px

@media screen and (max-width: 600px)
    .arrows
        display: none
    .swiper
        &-pagination
            position: absolute
            bottom: 24px
            left: 0
            right: 0
            margin: 0 auto
            z-index: 2
            &-bullet, .investment &-bullet
                width: 8px
                height: 8px
                border-radius: 50px
                padding-bottom: 0
                margin: 0 4px
                opacity: .7
                @for $var from 1 through 5
                    &:nth-child(#{$var}), .investment &:nth-child(#{$var})
                        background: #fff
                &-active
                    width: 24px
                    opacity: 1               

.cards-slider-page
    max-width: 1330px
    margin: 0 auto
    padding: 50px 10px 100px
    background-color: #0001
    @media screen and (max-width: 768px)
        padding: 100px 10px 30px

.cards-slider-page-image
    width: 100%
    margin: 10px 0
