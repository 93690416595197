.header
    &__title
        max-width: 650px
        font-size: 70px
        font-weight: 600
        line-height: 123%
        margin: 20px 0
        text-transform: uppercase
        @media (max-width: 1300px)
            font-size: calc(36px + (70 - 36) * ((100vw - 390px) / (1300 - 390)))
    &__description
        max-width: 430px
        font-weight: 400
        font-size: 20px
        line-height: 115%

.logo-main
    display: flex
    align-items: center
    &__text
        margin-left: 10px
        display: flex
        flex-direction: column
        @media(max-width: 768px)
            display: none
    &__title
        font-size: 23px
    &__subtitle
        font-size: 10px
        line-height: 100%

.main-banner
    position: relative
    width: 100%
    height: 552px
    &__layer
        position: absolute
        top: 0
        left: 0
        z-index: 0
        width: 100%
        height: 100%
        background: rgb(0,0,0)
        background: linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 100%)

.main-banner-inner
    display: flex
    padding: 0 5px
    height: 100%
    flex-direction: column
    justify-content: center
    align-items: flex-start
    text-align: center
    color: white
    @media(max-width: 768px)
        align-items: center


.banner__inner-text
    position: relative
    z-index: 1
    margin: 0px 0 0px
    text-align: left
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    @media(max-width: 768px)
        align-items: center
        text-align: center

.main-banner .banner-image
    position: absolute
    top: 0
    left: 0
    z-index: -1
    width: 100%
    height: 100%
    object-fit: cover


@media(max-width: 768px) 
    .main-banner
        height: 418px
        margin-top: 92px


@media(max-width: 400px) 
    .main-banner
        //height: 276px
        margin-top: 70px



.container
    max-width: 1300px
    margin: 0 auto
    padding: 0 24px
    box-sizing: content-box