.thumbs
    margin-top: 36px
    @media (max-width: 1199px)
        margin-top: 24px
    &__wrap
        display: block
        @media (max-width: 600px)
            display: none

.thumb
    border: 1px solid #68b8ff00
    transition: .4s
    width: 100%
    padding-bottom: 80%
    border-radius: 12px
    opacity: 0.5
    background: #fff center/cover no-repeat
    
   
.arrows
    top: 42%