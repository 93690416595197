
.news
   &__wrap
      padding: 70px 0 150px
      @media (max-width:768px)
         padding: 120px 0 70px
   &__title
      margin: 20px 0 48px
      line-height: 100%
   &__nav
      display: flex
      align-items: center
      color: #5D686D
      cursor: pointer
      font-size: 20px
      li
         &:hover
            color: black
      li:nth-child(2)
         margin: 20px
         &:hover
            color: #5D686D 
   &__cards
      display: grid
      grid-template-columns: repeat(3, 1fr) 
      gap: 24px
      @media (max-width: 800px)
         grid-template-columns: repeat(2, 1fr)
         gap: 16px
      @media (max-width:400px)
         grid-template-columns: repeat(1, 1fr)
         gap: 24px


.news-card
   &__image
      height: 280px
      cursor: pointer
      img
         border-radius: 24px
         width: 100%
         height: 100%
         object-fit: cover
   &__theme
      color: #CFAC6A
      font-size: 16px
      font-weight: 400
      display: inline-block
      padding: 8px 12px
      margin: 10px 0
      border-radius: 30px
      background-color: rgba(208, 172, 109, 0.08)
      line-height: 104%
   &__title
      margin-bottom: 15px
   &__text
      color: #7B8E97




