footer
    position: absolute
    bottom: 0
    left: 0 
    width: 100%
    background: #1F2E36
    padding: 48px 0

.footer
    display: flex
    justify-content: space-between
    color: #fff
    &__logo
        width: 40%
    &__wrap
        display: flex
        justify-content: space-between
        margin-left: 24px
        width: 60%
    &__link
        display: block
        margin-top: 12px

.networks
    display: flex
    margin-top: 20px
    &__item
        display: flex
        align-items: center
        justify-content: center
        width: 36px
        height: 36px
        background: #344F5E
        border-radius: 8px
        margin-right: 16px
        &:last-child
            margin-right: 0px
    & img 
        max-width: 20px

@media screen and (max-width: 768px)
    .footer
        display: flex
        margin-top: 20px
        &__wrap
            justify-content: flex-end
        &__item
            &:nth-child(1)
                display: none
            &:nth-child(2)
                display: none
        