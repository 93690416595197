.damac-hills2__inner
   padding: 120px 0 50px 0
   > div
      margin-bottom: 120px
      @media (max-width: 1330px)
         margin-bottom: calc(40px + (120 - 40) * ((100vw - 370px) / (1330 - 370)))
   @media (max-width: 768px)
      padding: 70px 0 30px 0
   @media (max-width: 400px)
      padding: 48px 0 0 0

.damac-hills2__container
   margin: 0 auto
   max-width: 930px
   > div
      margin-bottom: 120px
      @media (max-width: 1330px)
         margin-bottom: calc(70px + (120 - 70) * ((100vw - 370px) / (1330 - 370)))
   @media (max-width: 768px)
      padding: 70px 0 30px 0
   @media (max-width: 400px)
      padding: 48px 0 0 0

.damac-hills2
   &__title-wrap
      display: inline-block
      height: 100%
      background-color: rgba(255, 255, 255, 0.8)
      color: #000
      padding: 0 27px
      margin-left: 10%
      font-size: 64px
      line-height: 121%
      @media (max-width: 1330px)
         font-size: calc(32px + (64 - 32) * ((100vw - 370px) / (1330 - 370)))
      @media (max-width: 400px)
         width: 100%
         margin-left: 0%
      img
         max-width: 240px
         margin: 64px 0 40px
      span
         display: block

.searching
   text-align: center
   &__title
      margin-bottom: 20px
   &__sub-title
      display: block
      span
         color: #CFAC6A
         text-transform: uppercase
         font-weight: 600
   &__text
      margin: 32px 0
      @media (max-width: 1330px)
         margin: calc(20px + (32 - 20) * ((100vw - 370px) / (1330 - 370)))
   &__list
      max-width: 700px
      margin: 0 auto
      display: flex
      flex-wrap: wrap
      align-items: flex-start
      justify-content: space-between
      li
         margin: 0 10px
         width: 130px
         @media (max-width: 1330px)
            width: calc(80px + (130 - 80) * ((100vw - 400px) / (1330 - 400)))
      li:last-child
         margin-right: 0px
   &__list-item
      div
         height: 55px
      img
         max-width: 47px
      span
         display: block
         font-size: 13.5px

.community
   &__title
      text-align: center
      margin-bottom: 45px
      @media (max-width: 1330px)
         margin-bottom: calc(20px + (45 - 20) * ((100vw - 370px) / (1330 - 370)))
   &__list
      display: flex
      align-items: center
      justify-content: space-around
      .community__list-item
         margin-right: 30px
      .community__list-item:last-child
         margin-right: 0px
      @media (max-width: 500px)
         flex-direction: column
         .community__list-item
            margin-bottom: 20px
   &__list-item
      width: 27vw
      @media (max-width: 500px)
         width: 100%
      img
         width: 100%
         margin-bottom: -10px
      span
         display: block
         text-align: center
         font-weight: 700
         font-size: 16px
         background-color: #F0F3F5
         padding: 9px 0

.damac-hills2__flex-one
   display: flex
   align-items: center
   justify-content: space-between
   div:first-child
      margin-right: 20px


.damac-hills2__flex-two
   display: flex
   flex-direction: row-reverse
   align-items: center
   justify-content: space-between
   mrgin-bottom: 0
   .damac-hills2__flex-text
      img
         max-width: 330px
   h2
      margin: 20px 0
   div:last-child
      margin-right: 20px


.damac-hills2__flex-image
   flex-basis: 60%
   img
      width: 100%


.damac-hills2__flex-text
   flex-basis: 40%
   ul 
      list-style-type: none
      li
         line-height: 140%
         margin: 16px 0

.damac-hills2__big-image
   img
      width: 100%

.choise-home
   &__title
      font-weight: 400
      text-align: center
      margin-bottom: 40px
      @media (max-width: 1330px)
         margin-bottom: calc(20px + (40 - 20) * ((100vw - 370px) / (1330 - 370)))
      span
         font-weight: 600
   &__txt
      text-align: center
      margin-bottom: 40px
      @media (max-width: 1330px)
         margin-bottom: calc(20px + (40 - 20) * ((100vw - 370px) / (1330 - 370)))
      @media (max-width: 900px)
         margin-bottom: 0px
   &__list
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      @media (max-width: 900px)
         flex-direction: column
         align-items: center
      .choise-home__list-item:last-child
         h3
            font-size: 25px
   &__list-item
      width: 47%
      margin-top: 60px
      @media (max-width: 900px)
         width: 80%
      @media (max-width: 900px)
         width: 100%
   &__list-item-image
      position: relative
      z-index: -1
      img
         width: 100%
   &__list-item-discription
      background: #FFFFFF
      box-shadow: 3px 3px 5px 3px rgba(93, 93, 93, 0.25)
      border-radius: 15px
      padding: 20px
      width: 92%
      margin: -30px auto
      h3
         color: #41C4F2
         font-weight: 400
         font-size: 37px
         line-height: 122%
         @media (max-width: 1330px)
            font-size: calc(27px + (37 - 27) * ((100vw - 768px) / (1330 - 768)))
      p
         font-size: 16px
         line-height: 140%

.dh2-faq
   &__title
      text-align: center
      margin-bottom: 40px
      @media (max-width: 1330px)
         margin-bottom: calc(20px + (40 - 20) * ((100vw - 370px) / (1330 - 370)))
   &__list-item
      padding: 15px 0
      border-bottom: 1px solid #A3BAC6
      h3
         font-size: 16px
         margin-bottom: 3px
      p
         font-size: 16px

.damac-hills2__reasoans1
   flex-direction: row-reverse

.reasons
   display: flex
   justify-content: space-between
   @media (max-width: 600px)
      display: block
   &__title
      font-weight: 400
      font-size: 37px
      line-height: 140%
      margin: 27px 0
      @media (max-width: 1330px)
         font-size: calc(28px + (37 - 28) * ((100vw - 768px) / (1330 - 768)))
         margin: calc(15px + (27 - 15) * ((100vw - 768px) / (1330 - 768))) 0
   &__text
      flex-basis: 47%
      @media (max-width: 600px)
         margin-bottom: 30px
   &__list
      list-style-type: none
      display: flex
      justify-content: space-between
      flex-wrap: wrap
   &__list-item
      margin: 16px 0
      width: 48%
      @media (max-width: 1330px)
         margin: calc(10px + (16 - 10) * ((100vw - 768px) / (1330 - 768))) 0
      div
         height: 40px
      img
         max-width: 40px
      span
         display: block
         font-weight: 700
         font-size: 15px
         line-height: 120%
         margin: 24px 0 12px
         @media (max-width: 1330px)
            font-size: calc(11px + (15 - 11) * ((100vw - 768px) / (1330 - 768)))
            margin: calc(10px + (24 - 10) * ((100vw - 768px) / (1330 - 768))) 0 calc(6px + (12 - 6) * ((100vw - 768px) / (1330 - 768)))
      p
         font-size: 14px
         line-height: 130%
         @media (max-width: 1330px)
            font-size: calc(10px + (15 - 10) * ((100vw - 768px) / (1330 - 768)))
   &__image
      flex-basis: 47%
   img
      width: 100%

.properties
   text-align: center
   max-width: 1440px
   margin: 0 auto
   .properties__bg-image
      height: 340px
      width: 100%
      position: relative
      object-fit: cover
      z-index: -1
   &__text
      max-width: 740px
      margin: -100px auto 0
      padding: 30px
      background: #FFFFFF
      box-shadow: 2px 2px 4px rgba(93, 93, 93, 0.25)
      border-radius: 24px
      @media (max-width: 1330px)
         max-width: calc(340px + (740 - 340) * ((100vw - 370px) / (1330 - 370)))
   &__title
      font-size: 32px
      @media (max-width: 1330px)
         font-size: calc(15px + (32 - 15) * ((100vw - 370px) / (1330 - 370)))
   &__txt
      font-size: 16px
      line-height: 150%
      margin: 20px 0
      @media (max-width: 1330px)
         font-size: calc(8px + (16 - 8) * ((100vw - 370px) / (1330 - 370)))
         margin: calc(10px + (20 - 10) * ((100vw - 370px) / (1330 - 370))) 0
   &__list
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 30px
      @media (max-width: 1330px)
         margin-bottom: calc(10px + (30 - 10) * ((100vw - 370px) / (1330 - 370)))
   &__list-item
      display: flex
      align-items: center
      text-align: left
      font-size: 16px
      max-width: 200px
      @media (max-width: 1330px)
         font-size: calc(6px + (16 - 6) * ((100vw - 370px) / (1330 - 370)))
      h4
         font-weight: 400
         font-size: 30px
         margin-bottom: 10px
         @media (max-width: 1330px)
            font-size: calc(14px + (30 - 14) * ((100vw - 370px) / (1330 - 370)))
      img
         max-width: 42px
         margin-right: 10px
         position: relative
         z-index: 0
         @media (max-width: 1330px)
            max-width: calc(20px + (42 - 20) * ((100vw - 370px) / (1330 - 370)))
      span
         //white-space: nowrap

.damac-hills2__last-block
   padding: 0 20px 120px
   max-width: 600px
   margin: 0 auto
   @media (max-width: 1330px)
      padding-bottom: calc(40px + (120 - 40) * ((100vw - 370px) / (1330 - 370)))

.damac-hills2__last-title
   font-weight: 500
   line-height: 130%
   font-size: 32px
   text-align: center
   margin: 95px 0 45px
   @media (max-width: 1330px)
      font-size: calc(20px + (32 - 20) * ((100vw - 370px) / (1330 - 370)))
      margin: calc(40px + (95 - 40) * ((100vw - 370px) / (1330 - 370))) 0 calc(20px + (45 - 20) * ((100vw - 370px) / (1330 - 370)))

.damac-hills2__last-list
   img
      width: 100px
      margin-right: 20px
      @media (max-width: 1330px)
         width: calc(75px + (100 - 75) * ((100vw - 370px) / (1330 - 370)))
   img:last-child
      margin-right: 0px

@media (max-width: 768px)
   .damac-hills2__flex-one
      display: block
      div:last-child
         margin-left: 0
   .damac-hills2__flex-text
      width: 100%
   .damac-hills2__flex-two
      display: block
      div:last-child
         margin-right: 0


@media(max-width: 400px) 
   .damac-hills2__banner
      height: 418px
      margin-top: 92px
