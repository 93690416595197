
.fn-section
   margin-bottom: 120px
   @media (max-width: 1330px)
         margin-bottom: calc(48px + (120 - 48) * ((100vw - 370px) / (1330 - 370)))
   &__title
      margin-bottom: 48px
      @media (max-width: 1330px)
         margin-bottom: calc(32px + (48 - 32) * ((100vw - 370px) / (1330 - 370)))
   &__text-wrap
      max-width: 920px
      margin-bottom: 48px
      @media (max-width: 1330px)
         margin-bottom: calc(32px + (48 - 32) * ((100vw - 370px) / (1330 - 370)))
   &__txt
      margin-top: 28px
      max-width: 630px
      @media (max-width: 1330px)
         margin-top: calc(18px + (28 - 18) * ((100vw - 370px) / (1330 - 370)))
   &__list
      li
         span
            font-weight: 500
   &__image
      border-radius: 24px
      width: 100%
      object-fit: cover
   &__text
   &__over
      font-size: 24px
      padding-bottom: 20px
   &__link
      text-decoration: underline
      transition: .1s
      &:hover
         color: rgba(6, 163, 248, 0.5)


.news__cards-read-more
   margin-bottom: 48px
   @media (max-width: 1330px)
      margin-bottom: calc(32px + (48 - 32) * ((100vw - 370px) / (1330 - 370)))
