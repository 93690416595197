.flex
    margin: 48px 0 0px
    img
        width: 100%
        border-radius: 24px
    &__description
        margin-top: 48 px
        span
            color: #CFAC6A
        @media (max-width: 768px)
            margin-top: 32px
        a
            color: #CFAC6A
            text-decoration: underline
    
    &__item
        display: inline-block
        width: 100%
        height: 360px
        border-radius: 24px
        img
            height: 100%
        @media (max-width: 768px)
            width: 100%
            height: 230px
        @media (max-width: 600px)
            width: 100%

.show-card:nth-child(2)
    img
        object-fit: cover

.show-card:nth-child(3)
    img
        object-fit: cover



