.hamburger
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 27px
    height: 20px
    cursor: pointer
    margin-left: 19px
    &__wrap
        display: none
        @media (max-width: 768px)
            display: block
    .line
        height: 2px
        transition: .3s
        border-radius: 8px
        background-color: #fff



.open.hamburger
    transform: scale(1.1)
    .line__middle
        opacity: 1 
        display: none   
    .line__one
        transform: rotate(42deg) translate(8px, 5px) 
    .line__three
        transform: rotate(-42deg) translate(8px, -5px)