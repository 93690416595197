
.form
   background-color: white
   border-radius: 24px
   max-width: 900px
   margin: 0 auto
   padding: 25px
   display: flex
   flex-direction: column
   select, option
      font-size: 18px
   &__title
      text-align: center
   &__sub-title
      margin: 12px 0
   &__input
      font-size: 20px
      margin: 12px 0
      outline: none
      background-color: #FAFCFC
      border: 1px solid #CED3D6
      padding: 12px
      border-radius: 8px
      &:focus
         border: 1px solid #CFAC6A
      &.valid
         &:focus
            border: 2px solid red
   &__button
      cursor: pointer
      width: 35%
      font-size: 20px
      margin: 12px auto 0
      padding: 12px 0
      border-radius: 8px
      color: white
      background-color: #CFAC6A
      border: 0
      transition: .3s
      &:hover
         background-color: #9e8350
      @media (max-width: 768px)
         width: 100%

.PhoneInputInput
   font-size: 20px
   outline: none
   border: 0

.PhoneInput
   &:focus-within 
      border: 1px solid #CFAC6A
   margin: 12px 0
   outline: none
   background-color: #FAFCFC
   border: 1px solid #CED3D6
   padding: 12px
   border-radius: 8px




.draft
   position: fixed
   z-index: 100
   top: 0
   left: 0
   right: 0
   bottom: 0
   background-color: rgba(0, 0, 0, 0.8)

.draft2
   position: fixed
   z-index: 100
   top: 0
   left: 0
   right: 0
   bottom: 0
   background-color: rgba(0, 0, 0, 0)

.thanks
   position: fixed
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.8)
   padding: 20px
   border-radius: 20px
   text-align: center
   top: 50%
   left: 50%
   transform: translate(-50%, -50%)
   z-index: 100
   width: 40%
   background-color: white

.loader 
   width: 18px
   height: 18px
   border: 2px solid #FFF
   border-bottom-color: transparent
   border-radius: 50%
   display: inline-block
   box-sizing: border-box
   animation: rotation 1s linear infinite


@keyframes rotation 
   0% 
      transform: rotate(0deg)
   100% 
      transform: rotate(360deg)


.request__checkbox 
  display: none


.request__checkbox:checked + label:after 
  transform: scale(1)


.request__checkboxes 
  display: flex
  justify-content: space-between

.request__checkboxes label 
  position: relative
  display: flex
  align-items: center
  background-size: 25px
  border: 1px solid #CED3D6
  background-repeat: no-repeat
  background-position: center
  width: 32%
  height: 56px
  border-radius: 10px
  cursor: pointer

.request__checkboxes label::before 
  content: ""
  flex: 0 0 24px
  height: 24px
  border-radius: 50%
  align-self: flex-start
  margin-right: 24px

.request__checkboxes label::after 
  content: ""
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border: 1px solid #CFAC6A
  background-color: rgba(208, 172, 109, 0.1)
  border-radius: 10px
  transform: scale(0)

.request__label1 
  background-image: url(../../public/img/form/whatsapp-icon.png)

.request__label2 
  background-image: url(../../public/img/form/telegram.png)


.request__label3 
  background-image: url(../../public/img/form/Phone.png)


.form__whatsapp-btn 
   display: flex
   justify-content: center
   align-items: center
   border: 1px solid rgb(3, 197, 3)
   background-color: transparent
   border-radius: 8px
   color: white
   height: 56px
   transition: .3s
   cursor: pointer
   img
      max-width: 40px
   &:hover
      background-color: rgb(1, 170, 1)



