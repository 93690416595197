.accordion__wrap
    &>div
        &>div
            box-shadow: none!important
            border-bottom: 1px solid #E6EDF0
            border-radius: 12px!important
            padding: 24px
            &>div
                &:nth-child(1)
                    min-height: auto !important
                    padding: 0 !important
                    &>div
                        margin: 0!important
                        font-weight: 600
                        font-size: 1.5rem
                        line-height: 2rem
                &:nth-child(2)>div>div>div>div>div
                    padding: 16px 0 0!important
                    color: #70777A
  
    

