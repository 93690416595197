@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&display=swap')
*
  box-sizing: border-box
html
  font-size: 16px
  scroll-behavior: smooth
body
  margin: 0
  font-family: 'Jost', sans-serif
  font-weight: 400
  font-size: 1.25rem
  line-height: 2rem
  color: #1F2E36
  background-color: #FAFAFA
h1, h2, h3, h4, h5, h6, p, ul, li
  padding: 0
  margin: 0
.wrap
  max-width: 1300px
  margin: 0 auto
  padding: 0 36px
  box-sizing: content-box

.App
  position: relative
  min-height: 100vh
  padding-bottom: 180px

a
  text-decoration: none
  color: inherit

.title
  font-size: 3rem
  line-height: 4.5rem
  font-weight: 600

@media screen and (max-width: 1100px)
  html
    font-size: 14px
  
@media screen and (max-width: 500px)
  html
    font-size: 11px
  .wrap
    padding: 0 24px

.fixed-component 
  position: fixed
  display: flex
  align-items: center
  justify-content: center
  z-index: 50
  bottom: 20px
  right: 20px
  width: 70px
  height: 70px
  background-color: rgba(1, 170, 1, 0.7)
  border-radius: 50%
  padding: 10px
  animation: bounce 2s infinite
  cursor: pointer
  transition: .2s
  img
    display: block
    max-width: 35px
  &:hover
    background-color: rgb(1, 170, 1)


  @keyframes bounce 
    0%, 100% 
      transform: translateY(0)
    
    50% 
      transform: translateY(-10px)





